/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.1-v202504022256-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * A list of predicates by which to filter the items
 */
export interface PropertyFilterInputV1 {
    /**
     * The operator to apply on the provided property name
     */
    "operator": OperatorEnum;
    /**
     * The name of the item property to filter by
     */
    "propertyName": string;
    /**
     * The values to filter by. WITHIN and STRING_CONTAINS accept multiple values; other operators only allow one
     */
    "values": Array<models.PropertyInputV1>;
}

export enum OperatorEnum {
    EQUALS = 'EQUALS' as any,
    EQUALSIGNORECASE = 'EQUALS_IGNORE_CASE' as any,
    NOTEQUALS = 'NOT_EQUALS' as any,
    WITHIN = 'WITHIN' as any,
    STRINGCONTAINS = 'STRING_CONTAINS' as any,
    REGEXMATCHES = 'REGEX_MATCHES' as any,
    NOTREGEXMATCHES = 'NOT_REGEX_MATCHES' as any
}
