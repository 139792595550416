/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 66.14.1-v202504022256-CD
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import * as models from './models';

/**
 * The list of searches based on properties
 */
export interface PropertySearchV1 {
    /**
     * If provided, only search for items that are descendants of this asset
     */
    "ancestorId"?: string;
    /**
     * If provided, only search for items that are scoped to the workbooks contained in this folder
     */
    "folderId"?: string;
    /**
     * If true, the items found by this search will be included in the output. If false, then the results will be excluded from the output (even if found by another search)
     */
    "isInclude": boolean;
    /**
     * A list of predicates by which to filter the items
     */
    "predicates"?: Array<models.PropertyFilterInputV1>;
    /**
     * The type of search
     */
    "searchType": SearchTypeEnum;
    /**
     * A list of types by which to filter the items
     */
    "types": Array<string>;
}

export enum SearchTypeEnum {
    SWAPACROSSASSETS = 'SWAP_ACROSS_ASSETS' as any,
    FIXEDLIST = 'FIXED_LIST' as any,
    PROPERTY = 'PROPERTY' as any,
    MATERIALIZEDTABLEUUIDCOLUMN = 'MATERIALIZED_TABLE_UUID_COLUMN' as any
}
